import './app.scss';

import domready from 'domready';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import EventScroller from './utilities/js/event-scroller';
import ResizeHandler from './utilities/js/resize-handler';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import Lazysizes from 'Lazysizes';
import Notification from './components/notification/notification';
import Search from './components/search/search';
import Overlay from './components/overlay/overlay';
import { initScrollToAnchor } from './utilities/js/scroll-to-anchor';
import { loadYoutubeApi } from './utilities/js/helper';

// GLOBAL OPTIONS
const OPTIONS = {
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><b class="loading__loader"><i>loading...</i></b></b>'
};

const DEBUG = false;


window.mediaMatchHandler = new MediaMatchHandler();
window.youtubeApi = false;
window.youtubeApiLoading = false;

window._log = function (output) {
    if (typeof DEBUG !== 'undefined' && DEBUG !== false) {
        console.log(output);
    }
};

window._uniqueID = () => {

    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };

    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

window._createElementFromHTML = (htmlString) => {

    let div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    if (div.children.length > 1) {
        let itemsArray = [];
        Array.prototype.push.apply(itemsArray, div.children);

        return itemsArray;
    } else {
        return div.firstChild;
    }
};

const initContent = ($content) => {

    _log('start app');
    _log(Lazysizes, DEBUG);

    const $$videoTeaser = $content.querySelectorAll('[data-video-teaser]');

    if ($$videoTeaser.length > 0) {
        import('./components/video-teaser/video-teaser').then(({ VideoTeaser }) => {
            for (let i = 0; i < $$videoTeaser.length; i++) {
                const $videoTeaser = $$videoTeaser[i];
                const $videoTeaserAPI = new VideoTeaser($videoTeaser);
                $videoTeaserAPI.init();
            }
        });
    }

    const $selects = $content.querySelectorAll('select:not([no-uniform])');
    for (let i = 0; i < $selects.length; i++) {
        const $select = $selects[i];
        const $parentSelect = $select.parentNode;

        if (!$parentSelect.classList.contains('select')) {
            const $selectWrapper = document.createElement('div');
            $selectWrapper.classList.add('select');

            $parentSelect.insertBefore($selectWrapper, $select);

            $selectWrapper.appendChild($select);
        }
    }

    if (!document.querySelector('body').classList.contains('is--editmode')) {
        const $scrollTables = $content.querySelectorAll('table');
        if ($scrollTables.length > 0) {

            import('./components/scrolltable/scrolltable').then(({ Scrolltable }) => {

                for (let i = 0; i < $scrollTables.length; i++) {

                    let $scrollTable = $scrollTables[i];
                    new Scrolltable($scrollTable);
                }
            });
        }
    }

    const $editmodes = $content.querySelectorAll('[data-editmode]');
    if ($editmodes.length > 0) {
        for (let i = 0; i < $editmodes.length; i++) {
            let $editmode = $editmodes[i];

            $editmode.querySelector('legend').addEventListener('click', () => {
                if ($editmode.classList.contains('is--active')) {
                    $editmode.classList.remove('is--active');
                } else {
                    $editmode.classList.add('is--active');
                }
            });
        }
    }

    const $overlays = $content.querySelectorAll('[data-overlay="root"]');
    if ($overlays.length > 0) {

        for (let i = 0; i < $overlays.length; i++) {
            let $overlay = $overlays[i];

            new Overlay($overlay, {
                loader: OPTIONS.loader,
                onLoaded: ($overlay) => {
                    initContent($overlay);
                }
            });
        }
    }

    const $forms = $content.querySelectorAll('[data-form="root"]');
    if ($forms.length > 0) {

        import('./components/form-handler/form-handler').then(({ FormHandler }) => {

            for (let i = 0; i < $forms.length; i++) {
                let $form = $forms[i];

                new FormHandler($form, {
                    loader: OPTIONS.loader,
                    onSubmitted: ($form) => {
                        initContent($form);
                    }
                });
            }
        });
    }

    const $$formBuilder = $content.querySelectorAll('[data-formbuilder="root"]');
    if ($$formBuilder.length > 0) {

        import('./components/form-builder/form-builder').then(({ FormBuilder }) => {

            for (let i = 0; i < $$formBuilder.length; i++) {
                let $formBuilder = $$formBuilder[i];

                new FormBuilder($formBuilder, {
                    loader: OPTIONS.loader,
                    onSubmitted: ($formBuilder) => {
                        initContent($formBuilder);
                    }
                });
            }
        });
    }

    const $$uploader = $content.querySelectorAll('[data-uploader]');
    if ($$uploader.length > 0) {

        import('./components/uploader/uploader').then(({ Uploader }) => {

            for (let i = 0; i < $$uploader.length; i++) {
                const $uploader = $$uploader[i];
                const $uploaderAPI = new Uploader($uploader);
                $uploaderAPI.initialize();
            }
        });
    }

    const $profileGrids = $content.querySelectorAll('[data-profilegrid="root"]');
    if ($profileGrids.length > 0) {

        import('./areas/profile-grid/profile-grid').then(({ ProfileGrid }) => {
            for (let i = 0; i < $profileGrids.length; i++) {
                let $profileGrid = $profileGrids[i];

                new ProfileGrid($profileGrid, {
                    desktop: window.innerWidth >= OPTIONS.breakpoints.l
                });
            }
        });
    }

    const $ytVideos = $content.querySelectorAll('[data-youtube]');

    if ($ytVideos.length > 0) {
        const waitForConsent = setInterval(() => {
            if (typeof CookieConsent !== 'undefined') {
                console.log(CookieConsent);
                clearInterval(waitForConsent);
                if (CookieConsent.consent.marketing === true) { // eslint-disable-line
                    loadYoutubeApi();

                    window.onYouTubeIframeAPIReady = () => {
                        import('./components/youtube-player/youtube-player').then(({ YoutubePlayer }) => {
                            $ytVideos.forEach((media) => {
                                const $placeholder = media.closest('[data-media="youtube"]').querySelector('.media__restriction');

                                if ($placeholder) {
                                    $placeholder.remove();
                                }

                                const videoPlayer = new YoutubePlayer(media, {
                                    onEnd: () => {
                                        videoPlayer.destroy();
                                    }
                                });
                            });
                        });
                    };
                } else {
                    $ytVideos.forEach((media) => {
                        const $placeholder = media.closest('[data-media="youtube"]').querySelector('.media__restriction');

                        if ($placeholder) {
                            $placeholder.classList.add('is--shown');
                            $placeholder.closest('[data-media="youtube"]')?.classList.add('has--restriction');
                        }
                    });
                }
            } else {
                console.log('waiting for CookieContent');
            }
        }, 300);
    }

    const $copyButtons = $content.querySelectorAll('[data-copybutton]');
    if ($copyButtons.length > 0) {

        import('./components/copy-button/copy-button').then(({ CopyButton }) => {
            for (let i = 0; i < $copyButtons.length; i++) {
                let $copyButton = $copyButtons[i];
                new CopyButton($copyButton, {
                    onCopied: () => {

                        new Notification(translations.notifications.copy_link, {
                            autoclose: true
                        });
                    }
                });
            }
        });
    }

    const $dateInputs = $content.querySelectorAll('input[type="date"]');
    for (let i = 0; i < $dateInputs.length; i++) {
        const $dateInput = $dateInputs[i];
        let maxDate = null;

        if ($dateInput.getAttribute('max') !== null) {
            maxDate = $dateInput.getAttribute('max');
        }

        const datepicker = flatpickr($dateInput, {
            altFormat: 'd.m.Y',
            altInput: true,
            allowInput: true,
            disableMobile: false,
            locale: German,
            maxDate: maxDate
        });

        const $altDate = $dateInput.nextElementSibling;
        if ($altDate.classList.contains('flatpickr-input')) {
            $altDate.addEventListener('blur', () => {
                let altDate = $altDate.value.split('.');

                if (altDate.length === 3) {
                    const altDateDay = altDate[0],
                        altDateMonth = altDate[1],
                        altDateYear = altDate[2];

                    if (altDateDay.length === 2 && altDateMonth.length === 2 && altDateYear.length === 4) {
                        const newDate = altDateYear + '-' + altDateMonth + '-' + altDateDay;
                        datepicker.setDate(newDate, true);
                    }
                }
            });
        }
    }

    if (typeof Modernizr !== 'undefined') {

        if (!Modernizr.csshover) {

            const $hovers = $content.querySelectorAll('[data-hover]');
            if ($hovers.length > 0) {

                for (let i = 0; i < $hovers.length; i++) {
                    let $hover = $hovers[i];
                    let hoverClass = $hover.getAttribute('data-hover');

                    if (hoverClass === '') {
                        hoverClass = 'hover';
                    }

                    $hover.addEventListener('mouseover', () => {

                        if ($hover.classList.contains(hoverClass)) {
                            $hover.classList.remove(hoverClass);
                        } else {
                            $hover.classList.add(hoverClass);
                        }
                    });

                    document.addEventListener('touchstart', (e) => {
                        let isClickInside = $hover.contains(e.target);

                        if (!isClickInside) {
                            $hover.classList.remove(hoverClass);
                        }
                    });
                }
            }
        }
    }

    const $backToTop = document.querySelector('[data-back-to-top]');
    if ($backToTop) {
        import('./areas/back-to-top/back-to-top').then(({ BackToTop }) => {
            const backToTop = new BackToTop($backToTop);
            backToTop.init();
        });
    }

    const $mediaCenter = $content.querySelector('[data-media-center="root"]');
    if ($mediaCenter) {
        import('./components/media-center/media-center').then(({ MediaCenter }) => {
            const mediaCenter = new MediaCenter($mediaCenter, {
                onFilterUpdate: ($result) => {
                    initContent($result);
                }
            });
            mediaCenter.init();
        });
    }

    const $pager = $content.querySelector('[data-pager]');
    if ($pager) {
        import('./components/pager/pager').then(({ Pager }) => {
            const pager = new Pager($pager, {
                onPagerUpdate: ($result) => {
                    initContent($result);
                }
            });
            pager.init();
        });
    }

    const $$anchors = $content.querySelectorAll('a[href*="#"]');
    if ($$anchors.length > 0) {
        const anchorSearchList = generateFalseSlugByText();
        const falseAnchors = Object.keys(anchorSearchList);

        for (let i = 0; i < $$anchors.length; i++) {
            const $anchor = $$anchors[i];
            let newUrl = '';
            const baseUrl = $anchor.getAttribute('href').split('#')[0];
            let targetId = $anchor.getAttribute('href').split('#')[1].toLowerCase();
            targetId = encodeURI(targetId);

            if (falseAnchors.includes(targetId)) {
                newUrl = baseUrl + '#' + anchorSearchList[targetId];
            } else {
                newUrl = baseUrl + '#' + targetId.toLowerCase();
            }

            $anchor.setAttribute('href', newUrl);
        }
    }
};

window.checkInview = () => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('inview');
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });

    const $inviews = document.querySelectorAll('[data-inview]:not(.inview)');
    $inviews.forEach((target) => {
        observer.observe(target);
    });
};

const checkKeyfact = (scroller) => {

    const $keyfacts = document.querySelectorAll('[data-keyfact]');

    import('./components/keyfact/keyfact').then(({ Keyfact }) => {
        for (let i = 0; i < $keyfacts.length; i++) {
            let $keyfact = $keyfacts[i],
                onScreenStatus = scroller.onScreen($keyfact);

            if (onScreenStatus === true && $keyfact.getAttribute('initialized') === null) {
                new Keyfact($keyfact);
                $keyfact.setAttribute('initialized', true);
            }
        }
    });
};

const checkMoodVideo = (scroller) => {

    const $videos = document.querySelectorAll('[data-moodvideo]');

    for (let i = 0; i < $videos.length; i++) {
        let $video = $videos[i],
            onScreenStatus = scroller.onScreen($video);

        if (onScreenStatus === true) {
            $video.play();
            $video.setAttribute('playing', true);
        } else if ($video.paused !== true) {
            $video.pause();
            $video.removeAttribute('playing');
        }
    }
};

const checkBackToTopButton = () => {
    const position = window.pageYOffset;
    const $backToTop = document.querySelector('[data-back-to-top]');
    const visibilityOffset = document.body.scrollHeight / 2 - window.innerHeight / 2;


    if (position >= visibilityOffset) {
        $backToTop.classList.add('back-to-top--visible');
    } else {
        $backToTop.classList.remove('back-to-top--visible');
    }
};

/* this function generates dynamically false slugs from page anchors */
const generateFalseSlugByText = () => {
    let incorrectPageSlugs = {};
    const $$anchorObjects = document.querySelectorAll('.anchor');
    $$anchorObjects.forEach(($anchor) => {
        const anchorText = $anchor.innerText;
        const anchorId = $anchor.getAttribute('id');

        if (anchorId) {
            const falseSlug = encodeURI(anchorText.toLowerCase());
            const incorrectSlug = {
                [falseSlug]: anchorId,
            };

            incorrectPageSlugs = Object.assign(incorrectPageSlugs, incorrectSlug);
        }
    });
    return incorrectPageSlugs;
};

/* this function redirects false anchors given by location.hash to correct anchor */
const redirectAnchors = (callback) => {
    const anchorSearchList = generateFalseSlugByText();
    const falseAnchors = Object.keys(anchorSearchList);
    const locationHash = window.location.hash;
    let testAnchor = null;

    if (locationHash) {
        testAnchor = locationHash.split('#')[1];
    }

    if (falseAnchors.includes(testAnchor)) {
        window.location.hash = anchorSearchList[testAnchor];
    }

    if (typeof callback === 'function') {
        callback();
    }
};

domready(function () {
    const initApplication = () => {

        _log('CSS loaded', DEBUG);

        const $body = document.querySelector('body');

        new ResizeHandler();

        const eventScroller = new EventScroller({
            onScroll: (position, direction) => {
                _log({ 'position': position, 'direction': direction }, DEBUG);

                checkKeyfact(eventScroller);
                checkMoodVideo(eventScroller);
                checkBackToTopButton(eventScroller, position);
            }
        });
        eventScroller.initialize();

        const $searches = $body.querySelectorAll('[data-search="root"]');
        if ($searches.length > 0) {

            for (let i = 0; i < $searches.length; i++) {
                let $search = $searches[i];

                new Search($search, {
                    breakpoints: OPTIONS.breakpoints
                });
            }
        }

        const $languageSwitch = document.querySelector('[data-language]');
        if ($languageSwitch) {

            $languageSwitch.addEventListener('change', () => {
                document.location.href = $languageSwitch.value;
            });
        }

        const $optOutLink = document.getElementById('ga-opt-out');
        if ($optOutLink) {
            $optOutLink.addEventListener('click', (e) => {
                e.preventDefault();
                gaOptout();
            });
        }

        initContent($body, OPTIONS);

        window.setTimeout(() => {
            window.checkInview();
        }, 750);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.setTimeout(() => {
            window.dispatchEvent(contentUpdateEvent);
        }, 500);

        redirectAnchors(() => {
            initScrollToAnchor();
        });
    };

    initApplication();

});
