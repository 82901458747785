class ResizeHandler {
    constructor() {
        this.timer = 500;
        this.resizeTimer = '';
        this.activeEvent = '';
        this.event = [];
        this.eventNav = [];

        this.debug = false;

        this.dimensions = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        this.specialEvents = {
            'sizeMobile': {
                min: 320,
                max: 767
            },
            'sizeTablet': {
                min: 768,
                max: 1023
            },
            'sizeDesktop': {
                min: 1024,
                max: 9999
            }
        };

        this.initialize();
    }

    initialize() {

        Object.keys(this.specialEvents).forEach((value, index) => {
            if(typeof (Event) === 'function') {
                this.event[index] = new Event(value);
            }
            // IE 11 case
            else {
                this.event[index] = document.createEvent('Event');
                this.event[index].initEvent(value, true, true);
            }
        });


        this.events();
    }

    events() {

        let resizeTimer = '',
            lastResizeFireTime = 0;

        window.addEventListener('resize', () => {
            // let now = new Date().getTime();
            // let resizeIntervall = 100;

            // if(!this.resizeTimer) {

            //     this.resizeTimer = window.setTimeout(() => {
            //         this.resizeTimer = null;
            //         this.dimensions.width = window.innerWidth;
            //         this.dimensions.height = window.innerHeight;

            //         this.fireEvents();
            //     }, this.timer);

            // }

            let now = new Date().getTime();
            let minResizeTime = 100;

            if(!resizeTimer) {
                if(now - lastResizeFireTime > (3 * minResizeTime)) {
                    lastResizeFireTime = now;

                    this.dimensions.width = window.innerWidth;
                    this.dimensions.height = window.innerHeight;
                    this.fireEvents();
                }

                resizeTimer = setTimeout(() => {
                    resizeTimer = null;
                    lastResizeFireTime = new Date().getTime();

                    this.dimensions.width = window.innerWidth;
                    this.dimensions.height = window.innerHeight;
                    this.fireEvents();
                }, minResizeTime);
            }

        });
    }

    fireEvents() {
        Object.keys(this.specialEvents).forEach((value, index) => {
            if(this.dimensions.width >= this.specialEvents[value].min && this.dimensions.width <= this.specialEvents[value].max) {
                if(this.activeEvent !== value) {
                    this.activeEvent = value;
                    document.dispatchEvent(this.event[index]);

                    if(this.debug) {
                        console.log('Resize Event: ', this.event[index], 'was fired');
                    }
                }
            }
        });
    }

    add(event) {
        if(typeof event !== 'object') { return false; }

        Object.keys(event).forEach((value) => {
            this.specialEvents[value] = {};
            this.specialEvents[value].min = event[value].min;
            this.specialEvents[value].max = event[value].max;
        });
    }

}

export default ResizeHandler;